var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer" },
    [
      _c("b-container", [
        _c(
          "div",
          { staticClass: "line-container" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass: "text-center text-md-left",
                    attrs: { cols: "12", md: "6" },
                  },
                  [
                    _c("p", { staticClass: "m-0" }, [
                      _vm._v("© Creo Media Group 2020"),
                    ]),
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "text-center text-md-right",
                    attrs: { cols: "12", md: "6" },
                  },
                  [
                    _c("p", { staticClass: "m-0" }, [
                      _vm._v("Powered By Creo Media Manager 3.0"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }