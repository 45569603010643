var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header pb-4" },
    [
      _c("img", {
        staticClass: "line mt-n3",
        attrs: { src: require("@/assets/img/border2.svg") },
      }),
      _c(
        "b-container",
        { staticClass: "pt-3" },
        [
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c("b-col", { staticClass: "col-12 text-center" }, [
                _c("img", { attrs: { src: require("@/assets/img/logo.svg") } }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }