<template>
	<div class="header pb-4">
		<img src="@/assets/img/border2.svg" class="line mt-n3"/>
		<b-container class="pt-3">
			<b-row align-h="center">
				<b-col class="col-12 text-center">
					<img src="@/assets/img/logo.svg"/>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	export default {
		computed: {
			type() {
				return this.$store.getters.type
			}
		}
	}
</script>
