<template>
	<div id="app">
		<Header></Header>
		<router-view class="main" />
		<Footer></Footer>
	</div>
</template>
<style lang="scss">
	@import '@/assets/css/default.scss';
</style>
<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		name: 'App',
		components: {
			Header,
			Footer
		},
		mounted() {
			let password = new URLSearchParams(window.location.search).get('password')
				password = this.$route.query.password ? this.$route.query.password : password
			if (password) {
				this.$store.dispatch('password', password)
			}
		},
	}
</script>
